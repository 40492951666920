import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Hero from 'components/hero';
import Message from 'components/message';
import Release from 'components/release';
import Partners from 'components/partners';
import SignUp from 'components/signup';
import Social from 'components/icons';
import { graphql } from 'gatsby';

const Index = ({ data }) => (
  <Layout>
    <Hero data={data.homeJson.hero[0]} />
    <Message />
    <Release data={data.homeJson.release[0]} />
    <Partners />
    <div>
      <SignUp />
      <Social />
    </div>
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      release {
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      hero {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
