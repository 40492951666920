import React from 'react';
import { Wrapper, Title } from './message.css';

const Message = () => (
  <Wrapper>
    <Title>SAd Forever</Title>
    <p>
      this year, 1 in 4 people will feel stressed, anxious, sad or hopeless. the
      effects are as real as a broken arm and treatable just like any other
      medical condition. but most people don’t ask for help. stigma is a problem
      everywhere and usually the first barrier to moving forward. it’s what
      stopped me in the beginning.
    </p>
    <p>
      after taking the first steps to get the help i needed, i started the blue
      boy foundation with my family to continue raising awareness around mental
      health. and to recognize mental health awareness month, we’re proud to
      give all proceeds from “sad forever” to organizations around the world
      that are building movements to change stigma.
    </p>
    <p>
      and if you or somebody you know is struggling, please reach out for help.
      there are so many resources available wherever you are. for some options,
      go to{' '}
      <a
        href="https://lauvsongs.com/help"
        rel="noopener noreferrer"
        target="_blank"
      >
        lauvsongs.com/help
      </a>
      .
    </p>
  </Wrapper>
);

export default Message;
