import React, { Component, Fragment } from 'react';
import { Container, TextInput, FormSubmit } from './signup.css';

class SignUp extends Component {
  render() {
    return (
      <Fragment>
        <Container id="mailinglist_Signup_FORM">
          <TextInput placeholder="Enter Email Address" />
          <FormSubmit value="Join" />
        </Container>
      </Fragment>
    );
  }
}

export default SignUp;
