import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 730px;
  line-height: 1.5;
  color: #064c87;
  text-align: center;
  margin-bottom: 70px;

  p {
    text-align: left;
  }

  a {
    color: #064c87;
    position: relative;
    z-index: 1;
    text-decoration: none;
  }

  a::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: -0.1em;
    right: -0.1em;
    background-color: #fdea8d;
    transform-origin: bottom center;
    transform: scaleY(0.1);
    transition: all 0.1s ease-in-out;
  }

  a:hover::before {
    transform: scaleY(1);
    background-color: #fdea8d;
  }
`;

export const Title = styled.h2`
  margin-bottom: 40px;
  text-align: center;
  font-family: 'BDRmono 2006';
  font-size: 1.8em;
  color: #054c87;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  &:after {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
    background-color: #eae9af;
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0px;
  }
`;
