import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-height: 840px;
  overflow-y: hidden;
  margin-bottom: 10vw;
  padding-bottom: 10vh;
  @media (max-width: 600px) {
    padding-bottom: 15vh;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    svg:first-child {
      width: 40%;
    }
    svg:last-child {
      width: 50%;
      margin-top: 4px;
    }
  }
`;

export const Content = styled.div`
  position: absolute;
  padding: 0 15px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1168px;
  z-index: 1;
  top: 46px;

  @media (max-width: 600px) {
    top: 20px;
  }

  iframe {
    border: 8px solid #fff;
    margin-top: 10vw;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  }
`;
