import styled from 'styled-components';

export const Wrapper2 = styled.div`
  display: block;
  overflow: hidden;
  position: relative;
  height: auto;
  background: ${({ img }) => `transparent url(${img})`};
  background-size: cover;

  .bgImg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: salmon;
    z-index: 0;
    overflow: hidden;

    .gatsby-image-wrapper div {
      padding-bottom: 200% !important;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  padding: 100px 10px;

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 50px 15px;

    img {
      margin-bottom: 35px;
    }
  }

  img {
    width: 100%;
    max-width: 414px;
    border: 3px solid #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
`;

export const ReleaseLinks = styled.div`
  display: flex;
  flex-direction: column;

  a {
    display: inline-block;
    font-family: 'BDRmono 2006 Bold';
    background-color: #fff;
    padding: 14px 30px;
    border-radius: 3px;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8em;
    width: 100%;
    text-decoration: none;
    background-color: #064c87;
    color: #fff;
    transition: all 120ms ease-out;
    margin: 10px 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    &:hover {
      background-color: #eae9af;
      color: #064c87;
    }
  }
`;
