import React from 'react';
import { Wrapper, Title, Row, PartEntry } from './partners.css';
import BeyondBlueLogo from '../../../static/logo-beyondblue.png';
import TimeToChangeLogo from '../../../static/logo-timetochange.png';
import BringChangeToMindLogo from '../../../static/logo-bringchangetomind.png';
import CebuLogo from '../../../static/logo-cebu.png';
import MindLogo from '../../../static/logo-mind.png';
import StarmenLogo from '../../../static/logo-starmen.png';
import EaoLogo from '../../../static/logo-eao.png';
import HjarnLogo from '../../../static/logo-hjarnkoll.svg';

const partnerData = [
  {
    location: 'UK',
    name: 'Time to Change',
    url: 'https://www.time-to-change.org.uk/',
    logo: TimeToChangeLogo,
  },
  {
    location: 'Australia/NZ',
    name: 'beyondblue',
    url: 'https://www.beyondblue.org.au/',
    logo: BeyondBlueLogo,
  },
  {
    location: 'North America',
    name: 'Bring Change 2 Mind',
    url: 'https://bringchange2mind.org/',
    logo: BringChangeToMindLogo,
  },
  {
    location: 'Sweden',
    name: 'Hjärnkoll',
    url: 'https://hjarnkoll.se/',
    logo: HjarnLogo,
  },
  {
    location: 'Denmark',
    name: 'EN AF OS',
    url: 'http://www.en-af-os.dk/english.aspx',
    logo: EaoLogo,
  },
  {
    location: 'Netherlands',
    name: 'Starmen Sterk Stigma',
    url: 'https://www.samensterkzonderstigma.nl/',
    logo: StarmenLogo,
  },
  {
    location: 'Hong Kong',
    name: 'MIND',
    url: 'https://www.mind.org.hk/',
    logo: MindLogo,
  },
  {
    location: 'Cebu',
    name: 'Wellbeing Cluster',
    url: 'https://www.facebook.com/KaubanCebu/',
    logo: CebuLogo,
  },
];

const Partners = () => (
  <Wrapper>
    <Title>Recipients</Title>
    <Row>
      {partnerData.slice(0, 3).map(partner => (
        <PartEntry key={partner.name} href={partner.url} target="_blank">
          <img src={partner.logo} alt={partner.name} />
        </PartEntry>
      ))}
    </Row>
    <Row>
      {partnerData.slice(3).map(partner => (
        <PartEntry key={partner.name} href={partner.url} target="_blank">
          <img src={partner.logo} className="smallLogo" alt={partner.name} />
        </PartEntry>
      ))}
    </Row>
  </Wrapper>
);

export default Partners;
