import React from 'react';

import { Wrapper2, Content, ReleaseLinks } from './release.css';
import SadForeverCover from '../../../static/sadforever.jpg';

const Release = ({ data }) => (
  <Wrapper2 img={data.image.childImageSharp.fluid.src}>
    <Content>
      <img src={SadForeverCover} alt="Sad Forever Cover" />

      <ReleaseLinks>
        <a href="http://lauv.lnk.to/sadforever" id="spotify_DSP_BTN">
          Listen On Spotify
        </a>
        <a href="http://lauv.lnk.to/sadforever" id="applemusic_DSP_BTN">
          Listen On Apple Music
        </a>
        <a href="http://lauv.lnk.to/sadforever" id="amazonmusic_DSP_BTN">
          Listen On Amazon
        </a>
        <a href="http://lauv.lnk.to/sadforever" id="deezer_DSP_BTN">
          Listen On Deezer
        </a>
      </ReleaseLinks>
    </Content>
  </Wrapper2>
);

export default Release;
