import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Content, Title } from './hero.css';
import Img from 'gatsby-image';
import YoutubeEmbedVideo from 'react-youtube-embed';

import Logo from './lauv-logo';
import SadForever from './sad-forever-logo';

const Hero = ({ data }) => (
  <Wrapper>
    <Content>
      <Title>
        <Logo />
        <SadForever />
      </Title>

      <YoutubeEmbedVideo id="Klsi8CbSm8Y" suggestions={false} />
    </Content>
    <Img fluid={data.image.childImageSharp.fluid} />
  </Wrapper>
);

Hero.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Hero;
