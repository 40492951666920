import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 50px 0;
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
`;

export const Title = styled.h2`
  margin-bottom: 60px;
  text-align: center;
  font-family: 'BDRmono 2006';
  font-size: 1.8em;
  color: #054c87;
  position: relative;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-transform: uppercase;

  &:after {
    content: '';
    display: block;
    height: 10px;
    width: 100%;
    background-color: #eae9af;
    position: absolute;
    z-index: -1;
    left: 0;
    bottom: 0px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 50px;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .location {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1em;
    margin-bottom: 4px;
    color: #054c87;
  }

  .name {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 1em;
    color: #054c87;
  }
`;

export const PartEntry = styled.a`
  display: block;
  text-align: center;
  margin-bottom: 35px;
  text-decoration: none;

  img {
    width: 100%;
    max-width: 220px;

    &.smallLogo {
      max-width: 130px;
    }
  }
`;
